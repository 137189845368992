import React from 'react'
import currencyConverter from '@helpers/misc/currencyConverter'
import Translations from '@helpers/translations'
import styles from './styles.module.scss'

type ProductPriceProps = {
  variant?: string
  price: number
  showFrom?: boolean
  theme?: 'light' | 'dark'
}

const ProductPrice = ({
  price,
  variant,
  showFrom,
  theme = 'dark'
}: ProductPriceProps) => {
  return (
    <div
      className={`${styles.productPrice} ${styles[`theme_${theme}`]} ${
        variant ? styles[`productPrice_${variant}`] : ''
      }`}
    >
      {showFrom && (
        <p className={styles.productPrice__supportText}>
          {Translations.localise('common.products.prices.from')}
        </p>
      )}
      {currencyConverter({ price, decimals: false })}
    </div>
  )
}

export default ProductPrice

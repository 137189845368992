import { getLink } from '@helpers/env'
import SectionHeader from '@components/ui/SectionHeader'
import styles from './styles.module.scss'
import { analytics } from '@helpers/analytics'
import { jsonToArray } from '@helpers/contentful'
import Badge, { BadgeProps } from '@components/ui/Badge'
import ButtonMinimal from '@components/ui/Button/ButtonMinimal'
import { Button } from 'types/contentful'
import {
  getPageCategoryProductBreakdown,
  type SendEventProperties
} from '@components/ui/ProductsBreakdown/PBGrid'
import { upperFirstLetter } from '@helpers/utils'

const sendEvent = ({
  componentType,
  category,
  title,
  firstTimeUser
}: SendEventProperties) => {
  const props = {
    action: `Breakdown Collection ${componentType}`,
    category: category || getPageCategoryProductBreakdown(),
    label: !!title
      ? upperFirstLetter(title.toLowerCase())
      : 'Collection Breakdown',
    type: firstTimeUser ? 'New' : 'Existing'
  }
  analytics('Click', props)
}

type CardProps = {
  title: string
  category: string
  image: string
  handle: string
  badge?: BadgeProps
  theme: 'light' | 'dark'
  button: Button
}

function Card({
  card,
  firstTimeUser
}: {
  card: CardProps
  firstTimeUser: boolean
}) {
  const { category, handle, title, theme, image, badge, button } = card || {}

  const handleLinkClick = () => {
    sendEvent({
      componentType: 'Image',
      title,
      category,
      firstTimeUser
    })
  }
  return (
    <a
      className={`${styles.ProductsBreakdown__Card} ${
        styles[`theme_${theme}`]
      }`}
      href={getLink(handle)}
      onClick={handleLinkClick}
      aria-label={`Shop ${title}`}
    >
      {badge && (
        <Badge
          classes={styles.ProductsBreakdown__Card__badge}
          title={badge.title}
          themeColour={badge.themeColour}
        />
      )}
      <h3 className={styles.ProductsBreakdown__CardTitle}>{title}</h3>
      <img alt={title} src={image} width="320" height="438" />
      <ButtonMinimal
        classes={styles.ProductsBreakdown__CardText}
        variant="light"
        underlined={true}
      >
        {button.text}
      </ButtonMinimal>
    </a>
  )
}

type CardItem = {
  title: string
  badge?: {
    title: string
  }
  category: string
  button: Button
  handle: string
  theme: 'light' | 'dark'
  image: {
    url?: string
    file: {
      url: string
    }
  }
}

function productBreakdownCards(categories: CardItem[]): CardProps[] {
  return jsonToArray(categories || {}).map(
    ({ image, title, handle, theme, badge, category, button }) => {
      return {
        title: title,
        image: `https:${image?.file.url}`,
        handle: handle,
        theme: theme,
        badge,
        category,
        button
      }
    }
  )
}

type LandingPageProductsCardsProps = {
  categories: CardItem[]
  title: string
  byline: string
  firstTimeUser: boolean
}

export default function LandingPageProductsCards({
  categories,
  title,
  byline,
  firstTimeUser
}: LandingPageProductsCardsProps) {
  const cards = productBreakdownCards(categories)
  return (
    <section className={styles.ProductsBreakdown}>
      {title && (
        <div className={styles.ProductsBreakdown__header}>
          <SectionHeader title={title} byline={byline} variant="left_aligned" />
        </div>
      )}
      {!!cards?.length && (
        <ul className={styles.ProductsBreakdown__products}>
          {cards.map(card => {
            return (
              <li key={card.title}>
                <Card card={card} firstTimeUser={firstTimeUser} />
              </li>
            )
          })}
        </ul>
      )}
    </section>
  )
}

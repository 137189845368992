import React from 'react'
import LazyLoad from 'react-lazyload'
import { getLink } from '@helpers/env'
import styles from './styles.module.scss'

type CardMinimalProps = {
  foregroundColor: string
  title: string
  image: string
  url: string
}

function CardMinimal({ foregroundColor, title, image, url }: CardMinimalProps) {
  return (
    <div className={styles.cardMinimal}>
      <a href={getLink(url)}>
        <div className={styles.cardMinimal_image}>
          <LazyLoad height={435} once>
            <img src={image} alt={title} width="285" />
          </LazyLoad>
        </div>
        <div
          className={styles.cardMinimal_titleWrapper}
          style={{ backgroundColor: foregroundColor }}
        >
          <h3 className={styles.cardMinimal_title}>{title}</h3>
        </div>
      </a>
    </div>
  )
}

export default CardMinimal

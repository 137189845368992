import { getCurrencyCode, getLocale } from '@helpers/env'
import trackError from '@helpers/trackError'

type CurrencyConverterProps = {
  price: string | number | bigint
  currency?: string
  decimals?: boolean
}

type CurrencyOptions = {
  style: string
  currency: string
  minimumSignificantDigits?: number
  maximumSignificantDigits?: number
}

function currencyConverter({
  price,
  currency = getCurrencyCode(),
  decimals = true
}: CurrencyConverterProps): string {
  try {
    let props: CurrencyOptions = {
      style: 'currency',
      currency
    }

    if (!decimals) {
      props = {
        ...props,
        minimumSignificantDigits: 1,
        maximumSignificantDigits: 3
      }
    }

    const priceFormatted = new Intl.NumberFormat(getLocale(), props).format(
      Number(price)
    )
    return priceFormatted.replace(/\.0+$/, '')
  } catch (e) {
    const error = e as Error
    trackError(new Error(error.message))
    return String(price)
  }
}

export default currencyConverter

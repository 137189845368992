import React from 'react'
import { ButtonLink } from '@components/ui/Button'
import ProductPrice from '@components/ui/ProductPrice'
import { getLink } from '@helpers/env'
import styles from './styles.module.scss'

type CardContentProps = {
  title: string
  button: {
    uri: string
    text: string
  }
  byline: string
  onClickHandler: (arg: string) => void
  description: string
  price?: {
    price: number
    showFromCopy: boolean
  }
}

function CardContent({
  button,
  title,
  byline,
  description,
  price,
  onClickHandler
}: CardContentProps) {
  const linkClicked = () => onClickHandler('Link')
  return (
    <div className={styles.productCardDetails}>
      <a
        className={styles.productCardDetails__title}
        href={getLink(button.uri)}
        onClick={linkClicked}
      >
        <h3>{title}</h3>
      </a>
      <p className={styles.productCardDetails__byline}>{byline}</p>
      {!!description && (
        <p className={styles.productCardDetails__description}>{description}</p>
      )}
      {!!price && (
        <ProductPrice
          price={price.price}
          variant="mediumBold"
          showFrom={price.showFromCopy}
        />
      )}
      <ButtonLink
        classes={styles.productCardDetails__link}
        href={button.uri}
        variant="secondary"
        size="small"
        fullWidth="always"
        onClick={linkClicked}
      >
        {button.text}
      </ButtonLink>
    </div>
  )
}

export default CardContent

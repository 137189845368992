import React from 'react'
import { BadgeProps } from '@components/ui/Badge'
import CardImg from './CardImg'
import CardContent from './CardContent'
import styles from './styles.module.scss'

type CardDetailedProps = {
  title: string
  uri: string
  byline: string
  description: string
  onClickHandler: (args: { componentType: string; title: string }) => void
  imgSrc: string
  imgSrcSet?: string
  button: {
    uri: string
    text: string
  }
  badge: BadgeProps
  price?: {
    price: number
    showFromCopy: boolean
  }
}

CardDetailed.defaultProps = {
  onClickHandler: () => undefined,
  imgSrc: null,
  imgSrcSet: null,
  uri: null,
  badge: null,
  button: {},
  price: null
}

function CardDetailed({
  title,
  uri,
  description,
  imgSrc,
  imgSrcSet,
  badge,
  button,
  byline,
  price,
  onClickHandler
}: CardDetailedProps) {
  const handleClick = (componentType: string) =>
    onClickHandler({ componentType, title })

  return (
    <div className={styles.cardDetailed}>
      <CardImg
        badge={badge}
        onClickHandler={handleClick}
        link={button.uri || uri}
        title={title}
        imgSrc={imgSrc}
        imgSrcSet={imgSrcSet}
      />
      <CardContent
        button={button}
        onClickHandler={handleClick}
        title={title}
        byline={byline}
        description={description}
        price={price}
      />
    </div>
  )
}

export default CardDetailed
